import { Button } from "@progress/kendo-react-buttons";
import { Icon } from "@progress/kendo-react-common";
import type { SelectionRange } from "@progress/kendo-react-dateinputs";
import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useQuery } from "@tanstack/react-query";
import { sumBy, uniqBy } from "es-toolkit";
import {
	type ComponentProps,
	Fragment,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-use";
import { TypedDropDownButton } from "../TypedDropDownButton";
import { useAuditDialog } from "../dialogs/useAuditDialog";
import {
	type InvoicingAction,
	type InvoicingActions,
	InvoicingGrid,
} from "../grids/InvoicingGrid";
import {
	InvoiceType,
	NewJobStatus,
	TableNameType,
	type TypedGridColumnProps,
	invoiceTypeNames,
	jobApi,
	jobStatusNamesAndColors,
	toCurrency,
	toDatetimeString,
	toasted,
	uncheckGridSelection,
} from "../helpers";
import {
	useDialog,
	useGenericDateRangePicker,
	useGenericInvoicingStatusFilter,
} from "../helpersReact";

type InvoicingGridProps = ComponentProps<typeof InvoicingGrid>;
type Invoicing = InvoicingGridProps["data"][number];

type ActionItem = {
	text?: string;
	action: InvoicingAction;
	data: Invoicing;
};

type StatusItem = {
	text?: string;
	action: InvoicingAction;
	data: Invoicing;
};

const Status = ({ statusString }: { statusString: string }) => (
	<span>
		<Icon name="circle" />
		{statusString}
	</span>
);

const StatusItemRender = ({ item }: { item: StatusItem }) => (
	<Status statusString={item.text ?? ""} />
);

const useHandleAction = (
	retry: () => void,
	showAuditFor: (id: string | number) => void,
) => {
	const navigate = useNavigate();
	const location = useLocation();
	return useCallback(
		(item: ActionItem): undefined => {
			switch (item.action) {
				case "allowViewDetails":
					navigate(`/invoices/${item.data.id}`, {
						state: { from: location.pathname },
					});
					break;
				case "allowEdit":
					navigate(`/jobs/${item.data.id}`, {
						state: { from: location.pathname },
					});
					break;
				case "allowPreviewInvoice":
					toasted(
						jobApi.invoice
							.invoiceReportInvoiceDetail(item.data.id, {
								format: "blob",
							})
							.then((x) => window.open(URL.createObjectURL(x.data), "_blank")),
						"Previewing Invoice",
					);
					break;
				case "allowReInvoice":
					toasted(
						jobApi.job
							.jobChangeStatusCreate(
								item.data.id,
								NewJobStatus.READY_FOR_REINVOICE,
							)
							.then(retry),
						"Re-Invoiced",
					);
					break;
				case "allowMarkAsSent":
					toasted(
						jobApi.job
							.jobChangeStatusCreate(item.data.id, NewJobStatus.INVOICE_SENT)
							.then(retry),
						"Marked as Sent",
					);
					break;
				case "allowSendToInvoicingCheck":
					toasted(
						jobApi.job
							.jobChangeStatusCreate(
								item.data.id,
								NewJobStatus.READY_FOR_INVOICE,
							)
							.then(retry),
						"Sent to Invoicing Check",
					);
					break;
				case "allowSendToOperations":
					toasted(
						jobApi.job
							.jobChangeStatusCreate(item.data.id, NewJobStatus.COMPLETED)
							.then(retry),
						"Job Completed",
					);
					break;
				case "allowAudit":
					showAuditFor(item.data.id);
					break;
				default:
					return item.action;
			}
		},
		[location.pathname, navigate, showAuditFor, retry],
	);
};

const useExtraColumns = (handleAction: (item: ActionItem) => undefined) => {
	return useMemo(() => {
		const _columns: TypedGridColumnProps<Invoicing>[] = [
			{
				title: "Actions",
				cell: ({ dataItem }) => {
					const items: StatusItem[] = [];
					if (dataItem.actions.allowEdit) {
						items.push({
							action: "allowEdit",
							data: dataItem,
							text: "Edit",
						});
					}
					if (dataItem.actions.allowPreviewInvoice) {
						items.push({
							action: "allowPreviewInvoice",
							data: dataItem,
							text: "Preview Invoice",
						});
					}
					if (dataItem.actions.allowReInvoice) {
						items.push({
							action: "allowReInvoice",
							data: dataItem,
							text: "Re-Invoice",
						});
					}
					if (dataItem.actions.allowMarkAsSent) {
						items.push({
							action: "allowMarkAsSent",
							data: dataItem,
							text: "Mark as Sent",
						});
					}
					if (dataItem.actions.allowSendToInvoicingCheck) {
						items.push({
							action: "allowSendToInvoicingCheck",
							data: dataItem,
							text: "Send to Invoicing Check",
						});
					}
					if (dataItem.actions.allowSendToOperations) {
						items.push({
							action: "allowSendToOperations",
							data: dataItem,
							text: "Send to Operations",
						});
					}
					if (dataItem.actions.allowAudit) {
						items.push({
							text: "Audit",
							action: "allowAudit",
							data: dataItem,
						});
					}

					return (
						<td>
							<Button
								size="small"
								icon="eye"
								onClick={() =>
									handleAction({ action: "allowViewDetails", data: dataItem })
								}
							/>
							<TypedDropDownButton
								size="small"
								icon="more-vertical"
								items={items}
								itemRender={StatusItemRender}
								onItemClick={(x) => handleAction(x.item)}
								popupSettings={{ animate: false }}
							/>
						</td>
					);
				},
				field: "actions",
				width: "70px",
			},
		];
		return _columns;
	}, [handleAction]);
};

const useFetchData = (rangeValues: SelectionRange, statusValues: number[]) => {
	const _jobs = useQuery({
		queryKey: [
			"jobApi.invoice.invoiceList",
			rangeValues?.start,
			rangeValues?.end,
			statusValues,
		],
		queryFn: () =>
			jobApi.invoice
				.invoiceList({
					DateFrom: rangeValues.start?.toISOString(),
					DateTo: rangeValues.end?.toISOString(),
					StatusIds: statusValues,
				})
				.then((x) => x.data.data),
		initialData: [],
		refetchInterval: 1000 * 30, // 30 seconds
	});
	const jobs = useMemo(
		() =>
			_jobs.data.map((item) => {
				const endDate = new Date(item.endDate);
				const startDate = new Date(item.startDate);
				const invoiceDate = item.invoiceDate
					? new Date(item.invoiceDate)
					: undefined;

				const invoicing: Invoicing = {
					id: item.id,
					actions: item.actions as InvoicingActions,
					latestJobHistoryEventValue: item.latestJobHistoryEventValue ?? "",
					status: item.status,
					uniqueId: item.uniqueId ?? "",
					vatRate: item.vatRate ?? undefined,
					vatRateString: item.vatRate ? `${item.vatRate}%` : "",
					assignedToName: item.assignedToName ?? "",
					cost: item.cost ?? undefined,
					costString: item.cost
						? toCurrency(item.cost, item.customerCurrencyCode ?? undefined)
						: "",
					price: item.price ?? undefined,
					priceString: item.price
						? toCurrency(item.price, item.customerCurrencyCode ?? undefined)
						: "",
					customerId: item.customerId,
					customerName: item.customerName ?? "",
					customerInvoiceType: item.customerInvoiceType,
					customerInvoiceTypeString: invoiceTypeNames[item.customerInvoiceType],
					customerCurrencyCode: item.customerCurrencyCode ?? undefined,
					grossSum: item.grossSum ?? undefined,
					grossSumString: item.grossSum
						? toCurrency(item.grossSum, item.customerCurrencyCode ?? undefined)
						: "",
					invoiceNumber: item.invoiceNumber ?? "",
					purchaseOrderNumber: item.purchaseOrderNumber ?? "",
					statusString: jobStatusNamesAndColors[item.status].name,
					totalLegsWithPods: item.totalLegsWithPods,
					endDate,
					endDateString: toDatetimeString(endDate),
					startDate,
					startDateString: toDatetimeString(startDate),
					invoiceDate,
					invoiceDateString: invoiceDate ? toDatetimeString(invoiceDate) : "",
					totalLegsNeedingPods: item.totalLegsNeedingPods,
					ratioPodsString: `${item.totalLegsWithPods}/${item.totalLegsNeedingPods}`,
					ratioPodsGoodsString: `${item.totalGoodsWithPods}/${item.totalGoodsNeedingPods}`,
				};
				return invoicing;
			}),
		[_jobs.data],
	);
	return { data: jobs, retry: _jobs.refetch, loading: _jobs.isFetching };
};

const useSelected = (invoicing: Invoicing[]) => {
	const [selected, setSelected] = useState<Set<number>>(new Set());
	const selectedInvoices = useMemo(
		() => invoicing.filter((x) => selected.has(x.id)),
		[invoicing, selected],
	);
	const selectedCustomerCurrencyCode = useMemo(
		() => selectedInvoices[0]?.customerCurrencyCode,
		[selectedInvoices],
	);
	const selectedTotalGrossSum = useMemo(
		() => selectedInvoices.reduce((acc, x) => acc + (x.grossSum ?? 0), 0),
		[selectedInvoices],
	);
	const buttonText = useMemo(
		() =>
			selected.size
				? `Generate Invoice (${selected.size} for ${toCurrency(
						selectedTotalGrossSum,
						selectedCustomerCurrencyCode,
					)})`
				: "Generate Invoice",
		[selected.size, selectedTotalGrossSum, selectedCustomerCurrencyCode],
	);
	return { setSelected, buttonText, selectedInvoices };
};

const useExtraFilters = () => {
	const [dateRangeEl, rangeValues] = useGenericDateRangePicker("Invoicing");
	const [filterStatusEl, statusValues] =
		useGenericInvoicingStatusFilter("Invoicing");
	return [
		<Fragment key="extraFilters">
			{filterStatusEl}
			{dateRangeEl}
		</Fragment>,
		rangeValues,
		statusValues,
	] as const;
};

type InvoicingProceed = {
	customerName: string;
	jobNumbers: string[];
	invoiceTotal: string;
};

const useExtraButtons = (
	selectedInvoices: Invoicing[],
	buttonText: string,
	retry: () => void,
) => {
	const [loading, setLoading] = useState(false);
	const [date, setDate] = useState(new Date());
	const handleApprove = async () => {
		setLoading(true);
		const selectedCheckboxIds = selectedInvoices.map((x) => x.id);
		await toasted(
			jobApi.invoice
				.invoiceScheduleCreate({
					jobIds: selectedCheckboxIds,
					date: date.toISOString(),
				})
				.then(retry)
				.then(() => showDialog(false))
				.then(uncheckGridSelection)
				.finally(() => setLoading(false)),
			"Invoices scheduled successfully!",
		);
	};

	const invoicesByUniqueCustomer = uniqBy(
		selectedInvoices,
		(x) => x.customerId,
	);

	const invoicesProceed: InvoicingProceed[] = [];
	for (const invoiceByUniqueCustomer of invoicesByUniqueCustomer) {
		const invoices = selectedInvoices.filter(
			(x) => x.customerId === invoiceByUniqueCustomer.customerId,
		);
		if (invoiceByUniqueCustomer.customerInvoiceType === InvoiceType.Single) {
			invoicesProceed.push({
				customerName: invoiceByUniqueCustomer.customerName,
				jobNumbers: invoices.map((x) => x.uniqueId),
				invoiceTotal: toCurrency(
					sumBy(invoices, (x) => x.grossSum ?? 0),
					invoiceByUniqueCustomer.customerCurrencyCode,
				),
			});
		} else if (
			invoiceByUniqueCustomer.customerInvoiceType === InvoiceType.Multiple
		) {
			for (const invoice of invoices) {
				invoicesProceed.push({
					customerName: invoice.customerName,
					jobNumbers: [invoice.uniqueId],
					invoiceTotal: toCurrency(
						invoice.grossSum || 0,
						invoice.customerCurrencyCode,
					),
				});
			}
		}
	}

	const hasFailedInvoices = selectedInvoices.some(
		(x) => x.status === NewJobStatus.INVOICE_FAILED,
	);

	const dialogContent = (
		<>
			<p>
				Are you sure you want to generate invoices for the following customers?
			</p>
			{
				<table style={{ width: "100%" }}>
					<thead>
						<tr>
							<th>Customer Name</th>
							<th>Job Numbers</th>
							<th>Invoice Total</th>
						</tr>
					</thead>
					<tbody>
						{invoicesProceed.map((x) => (
							<tr key={x.customerName}>
								<td>{x.customerName}</td>
								<td>
									{x.jobNumbers.map((jobNumber) => (
										<div key={jobNumber}>{jobNumber}</div>
									))}
								</td>
								<td>{x.invoiceTotal}</td>
							</tr>
						))}
					</tbody>
				</table>
			}
			<p>Please select the posting date before confirming the invoice:</p>
			<input
				type="date"
				value={date.toISOString().slice(0, 10)}
				onChange={(e) => setDate(new Date(e.target.value))}
			/>
			<DialogActionsBar>
				<Button onClick={() => showDialog(false)} themeColor="secondary">
					No
				</Button>
				<Button
					onClick={handleApprove}
					themeColor="primary"
					disabled={!selectedInvoices.length || loading}
				>
					Yes
				</Button>
			</DialogActionsBar>
		</>
	);
	const [showDialog, dialog] = useDialog(
		dialogContent,
		"Do you want to proceed?",
	);
	const buttons = (
		<Button
			icon="plus"
			themeColor="primary"
			onClick={() => showDialog()}
			title={
				hasFailedInvoices
					? "Cannot generate invoice for failed invoices"
					: undefined
			}
			disabled={!selectedInvoices.length || hasFailedInvoices}
		>
			{buttonText}
		</Button>
	);
	return [buttons, dialog] as const;
};

export const InvoicingPage2 = () => {
	const [extraFilters, rangeValues, statusValues] = useExtraFilters();
	const [showAuditFor, auditDialog] = useAuditDialog(TableNameType.Job);
	const { data, retry, loading } = useFetchData(rangeValues, statusValues);
	const handleAction = useHandleAction(retry, showAuditFor);
	const extraColumns = useExtraColumns(handleAction);
	const { setSelected, buttonText, selectedInvoices } = useSelected(data);
	const [extraButtons, dialog] = useExtraButtons(
		selectedInvoices,
		buttonText,
		retry,
	);
	return (
		<>
			<title>Invoicing</title>
			{auditDialog}
			{dialog}
			<InvoicingGrid
				data={data}
				extraColumns={extraColumns}
				extraFilters={extraFilters}
				refresh={retry}
				loading={loading}
				onSelectionChange={setSelected}
				extraButtons={extraButtons}
				footer={{
					price: (data) => (
						<td>{toCurrency(sumBy(data, (x) => x.price ?? 0))}</td>
					),
					grossSum: (data) => (
						<td>{toCurrency(sumBy(data, (x) => x.grossSum ?? 0))}</td>
					),
					cost: (data) => (
						<td>{toCurrency(sumBy(data, (x) => x.cost ?? 0))}</td>
					),
				}}
			/>
		</>
	);
};
